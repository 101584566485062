var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"xl":"8","lg":"8","md":"12","sm":"12"}},[_c('b-card',{attrs:{"title":"Add New Affiliate"}},[_c('b-card-sub-title',{staticClass:"mb-2"},[_vm._v(" Complete the form below to add this affiliate account to the AMP ")]),_c('validation-observer',{ref:"addAffiliateValidation"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"lg":"8","md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"firstname"}},[_vm._v("Firstname ")]),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" *")]),_c('validation-provider',{attrs:{"name":"firstname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"firstname","state":errors.length > 0 ? false:null,"name":"firstname","placeholder":"Firstname"},model:{value:(_vm.firstname),callback:function ($$v) {_vm.firstname=$$v},expression:"firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"lastname"}},[_vm._v("Lastname ")]),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" *")]),_c('validation-provider',{attrs:{"name":"lastname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lastname","state":errors.length > 0 ? false:null,"name":"lastname","placeholder":"Lastname"},model:{value:(_vm.lastname),callback:function ($$v) {_vm.lastname=$$v},expression:"lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"12"}},[_c('b-form-group',{attrs:{"description":"The affiliate username must be unique"}},[_c('label',{attrs:{"for":"username"}},[_vm._v("Username ")]),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" *")]),_c('validation-provider',{attrs:{"name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username","state":errors.length > 0 ? false:null,"name":"username","placeholder":"Username"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"upstream"}},[_vm._v("Upstream Affiliate ")]),_c('v-select',{attrs:{"id":"upstream","options":_vm.upstreamAffiliates,"label":"title"},model:{value:(_vm.upstreamAffiliate),callback:function ($$v) {_vm.upstreamAffiliate=$$v},expression:"upstreamAffiliate"}})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"12"}},[_c('b-form-group',{attrs:{"description":"The affiliate email must be unique"}},[_c('label',{attrs:{"for":"email"}},[_vm._v("Email ")]),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" *")]),_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email","state":errors.length > 0 ? false:null,"name":"email","placeholder":"affiliate@email.com"},model:{value:(_vm.emailAddr),callback:function ($$v) {_vm.emailAddr=$$v},expression:"emailAddr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"8","md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"password"}},[_vm._v("Password ")]),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" *")]),_c('validation-provider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"password","type":"password","state":errors.length > 0 ? false:null,"name":"password","placeholder":"......................"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-2",attrs:{"lg":"8","md":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.addAffiliate}},[_vm._v(" Add Affiliate ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"to":{ name: 'affiliate-accounts' },"variant":"outline-warning"}},[_vm._v(" Go Back ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }